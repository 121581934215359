export const iconBaseUrl: string = '/assets/icons/';
export const ICONS = {
  filter: iconBaseUrl + 'filter.svg',
  sort: iconBaseUrl + 'sort.svg',
  lock: iconBaseUrl + 'lock.svg',
  chat: iconBaseUrl + 'chat.svg',
  add: iconBaseUrl + 'add.svg',
  arrow: iconBaseUrl + 'arrow.svg',
  arrowLeft: iconBaseUrl + 'arrow-left.svg',
  arrowRight: iconBaseUrl + 'arrow-right.svg',
  arrowDown: iconBaseUrl + 'arrow-down.svg',
  arrowBottom: iconBaseUrl + 'arrow-bottom.svg',
  arrowSlide: iconBaseUrl + 'arrow-slide.svg',
  arrowBold: iconBaseUrl + 'bold-arrow.svg',
  burger: iconBaseUrl + 'burger.svg',
  cancel: iconBaseUrl + 'cancel.svg',
  closeCircle: iconBaseUrl + 'close-circle.svg',
  eyeHide: iconBaseUrl + 'eye-hide.svg',
  eyeShow: iconBaseUrl + 'eye-show.svg',
  menuBox: iconBaseUrl + 'menu-box.svg',
  minus: iconBaseUrl + 'minus.svg',
  minusSquare: iconBaseUrl + 'minus-square.svg',
  plusSquare: iconBaseUrl + 'plus-square.svg',
  search: iconBaseUrl + 'search.svg',
  tickCircle: iconBaseUrl + 'tick-circle.svg',
  ticketDiscount: iconBaseUrl + 'ticket-discount.svg',
  tune: iconBaseUrl + 'tune.svg',
  website: iconBaseUrl + 'website.svg',
  down: iconBaseUrl + 'down.svg',
  google: iconBaseUrl + 'google.svg',
  sale: iconBaseUrl + 'sale.svg',
  line: iconBaseUrl + 'line.svg',
  square: iconBaseUrl + 'square.svg',
  rectangle: iconBaseUrl + 'rectangle.svg',
  list: iconBaseUrl + 'list.svg',
  listAlt: iconBaseUrl + 'list-alt.svg',
  grid: iconBaseUrl + 'grid.svg',
  basket: iconBaseUrl + 'basket.svg',
  basketPrimary: iconBaseUrl + 'basket-primary.svg',
  ourDepartments: iconBaseUrl + 'our-departments.svg',
  check: iconBaseUrl + 'check.svg',
  addPrice: iconBaseUrl + 'add-price.svg',
  minusPrice: iconBaseUrl + 'minus-price.svg',
  profileCircle: iconBaseUrl + 'profile-circle.svg',
  profile: iconBaseUrl + 'profile.svg',
  menu: iconBaseUrl + 'menu.svg',
  close: iconBaseUrl + 'close.svg',
  closeSmall: iconBaseUrl + 'close-small.svg',
  building: iconBaseUrl + 'building.svg',
  orders: iconBaseUrl + 'orders.svg',
  door: iconBaseUrl + 'door.svg',
  marker: iconBaseUrl + 'marker.svg',
  markerOutline: iconBaseUrl + 'marker-outline.svg',
  truck: iconBaseUrl + 'truck.svg',
  delete: iconBaseUrl + 'delete.svg',
  create: iconBaseUrl + 'create.svg',
  logout: iconBaseUrl + 'logout.svg',
  bSheetIndicator: iconBaseUrl + 'b-sheet-indicator.svg',
  signUp: iconBaseUrl + 'sign up.svg',
  google2: iconBaseUrl + 'google2.svg',
  ellipse1: iconBaseUrl + 'ellipse1.svg',
  ellipse2: iconBaseUrl + 'ellipse2.svg',
  discount: iconBaseUrl + 'discount.svg',
  emptyBasket: iconBaseUrl + 'empty-basket.svg',
  arrowBack: iconBaseUrl + 'arrow-back.svg',
  checkCircle: iconBaseUrl + 'check-circle.svg',
  document: iconBaseUrl + 'document.svg',
  work: iconBaseUrl + 'work.svg',
  home: iconBaseUrl + 'home.svg',
  edit: iconBaseUrl + 'edit.svg',
  eye: iconBaseUrl + 'eye.svg',
  boxTick: iconBaseUrl + 'box-tick.svg',
  tickCircleFull: iconBaseUrl + 'tick-circle-full.svg',
  right: iconBaseUrl + 'right.svg',
  bag: iconBaseUrl + 'bag.svg',
  box: iconBaseUrl + 'box.svg',
  boxDone: iconBaseUrl + 'box-done.svg',
  boxSearch: iconBaseUrl + 'box-search.svg',
  truckFast: iconBaseUrl + 'truck-fast.svg',
  logo: iconBaseUrl + 'logo.svg',
  whatsapp: iconBaseUrl + 'whatsapp.svg',
  sms: iconBaseUrl + 'sms.svg',
  map: iconBaseUrl + 'map.svg',
  globe: iconBaseUrl + 'globe.svg',
  call: iconBaseUrl + 'call.svg',
  homeFill: iconBaseUrl + 'home-fill.svg',
  workFill: iconBaseUrl + 'work-fill.svg',
  circle: iconBaseUrl + 'circle.svg',
  truckStroke: iconBaseUrl + 'truck-stroke.svg',
  rydTruckStroke: iconBaseUrl + 'ryd-truck-stroke.svg',
  filters: iconBaseUrl + 'filters.svg',
  stock: iconBaseUrl + 'stock.svg',
  aboutList0: iconBaseUrl + 'about-list-0.svg',
  aboutList1: iconBaseUrl + 'about-list-1.svg',
  aboutList2: iconBaseUrl + 'about-list-2.svg',
  aboutList3: iconBaseUrl + 'about-list-3.svg',
  phone: iconBaseUrl + 'phone.svg',
  arrowLeft2: iconBaseUrl + 'arrow-left-2.svg',
  successCircle: iconBaseUrl + 'success-circle.svg',
  warning: iconBaseUrl + 'warning.svg',
  warningTriangle: iconBaseUrl + 'warning-triangle.svg',
  googleGray: iconBaseUrl + 'google-gray.svg',
  facebook: iconBaseUrl + 'facebook.svg',
  facebookGray: iconBaseUrl + 'facebook-gray.svg',
  facebookBlue: iconBaseUrl + 'facebook-blue.svg',
  apple: iconBaseUrl + 'apple.svg',
  appleGray: iconBaseUrl + 'apple-gray.svg',
  appleBlack: iconBaseUrl + 'apple-black.svg',
  mastercard: iconBaseUrl + 'mastercard.svg',
  visa: iconBaseUrl + 'visa.svg',
  rydArrowLeft: iconBaseUrl + 'ryd-arrow-left.svg',
  rydArrowDown: iconBaseUrl + 'ryd-arrow-down.svg',
  rydLike: iconBaseUrl + 'ryd-like.svg',
  rydClose: iconBaseUrl + 'ryd-close.svg',
  rydLogo: iconBaseUrl + 'ryd-logo.svg',
  key: iconBaseUrl + 'key.svg',
  inbox: iconBaseUrl + 'inbox.svg',
  bookOpen: iconBaseUrl + 'book-open.svg',
  clipboard: iconBaseUrl + 'clipboard.svg',
  headphones: iconBaseUrl + 'headphones.svg',
  logOut: iconBaseUrl + 'log-out.svg',
  mapPin: iconBaseUrl + 'map-pin.svg',
  calendar: iconBaseUrl + 'calendar.svg',
  percent: iconBaseUrl + 'percent.svg',
  rotate: iconBaseUrl + 'rotate-cw.svg',
  filePlus: iconBaseUrl + 'file-plus.svg',
  fileText: iconBaseUrl + 'file-text.svg',
  clock: iconBaseUrl + 'clock.svg',
  file: iconBaseUrl + 'file.svg',
  package: iconBaseUrl + 'package.svg',
  download: iconBaseUrl + 'download.svg',
  dollar: iconBaseUrl + 'dollar.svg',
  trash: iconBaseUrl + 'trash.svg',
  copy: iconBaseUrl + 'copy.svg',
  shoppingCart: iconBaseUrl + 'shopping-cart.svg',
  chevronLeft: iconBaseUrl + 'chevron-left.svg',
  refresh: iconBaseUrl + 'refresh.svg',
  heart: iconBaseUrl + 'heart.svg',
  zap: iconBaseUrl + 'zap.svg',
  rocket: iconBaseUrl + 'rocket.svg',
  award: iconBaseUrl + 'award.svg',
  mail: iconBaseUrl + 'mail.svg',
  navigation: iconBaseUrl + 'navigation.svg',
  arrowUp: iconBaseUrl + 'arrow-up.svg',
  plus: iconBaseUrl + 'plus.svg',
  star: iconBaseUrl + 'star.svg',
  shekel: iconBaseUrl + 'shekel.svg',
};
export type Icon = keyof typeof ICONS;
export const passwordMinLength: number = 6;
export const passwordMaxLength: number = 40;
export const passwordRegex = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
export const emailMaxLength: number = 50;
export const DISCOUNT_DEPARTMENT_ID_FOR_SALES: number = -1;
export const PREVIOUSLY_BOUGHT_DEPARTMENT_ID_FOR_SALES: number = -2;
export const SPECIAL_DEPARTMENTS_NAMES = ['new', 'discount', 'favorites', 'previous'];
export const missingProductImage: string = './assets/img/missing-item.webp';
export const numberOnlyPattern: string = '^[0-9]*$';
export const phoneValidationRegex =
  /^(?:(?:(\+?972|\(\+?972\)|\+?\(972\))(?: |\.|-)?([1-9]\d?))|(0[23489])|(0[57]\d))(?: |\.|-)?([^0\D]\d{2}(?: |\.|-)?\d{4})$/;
export const CHAR_CODE_0 = 48;
export const CHAR_CODE_9 = 59;
